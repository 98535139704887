import { useContext } from "react"
import { CartProvider, CartCtx } from "./Cart/Provider"
import { CustomerProvider, CustomerCtx } from "./Customer/Provider"
import { SearchProvider, SearchCtx } from "./Search/Provider"
import { AppProvider, AppCtx } from "./App/Provider"
import { WindowProvider, WindowCtx } from "./Window/Provider"

const useActions = (ctx, name) => {
  const { dispatch } = useContext(ctx)

  if (Array.isArray(name)) {
    return name.reduce((actions, name) => {
      actions[name] = (payload) =>
        dispatch({
          type: name,
          payload,
        })
      return actions
    }, {})
  } else {
    return {
      [name]: (payload) =>
        dispatch({
          type: name,
          payload,
        }),
    }
  }
}

const useContextState = (ctx, name = "*") => {
  const { state } = useContext(ctx)
  if (Array.isArray(name)) {
    return name.reduce((states, name) => {
      states[name] = state[name]
      return states
    }, {})
  } else {
    return name === "*"
      ? state
      : {
          [name]: state[name],
        }
  }
}

export {
  useActions,
  useContextState,
  CartProvider,
  CartCtx,
  CustomerProvider,
  CustomerCtx,
  SearchProvider,
  SearchCtx,
  AppProvider,
  AppCtx,
  WindowProvider,
  WindowCtx,
}

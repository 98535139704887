import wrapRootElement from './src/reduxWrapper'

import store, {appActions} from './src/state'

import './src/styles/constants.scss'
import './src/styles/global.scss'
import './src/styles/icons.css'

function onPreRouteUpdate({ location, prevLocation }) {}

function onRouteUpdate({ location, prevLocation }) {}

function onClientEntry() {
	// console.log("We've started!", store.getState().App.imBusy)
	// if ('serviceWorker' in navigator) {
	// 		navigator.serviceWorker
	// 			.register('/sw.js')
	// 	}
}

function onInitialClientRender() {
	// console.log('ReactDOM.render has executed', appActions.toggle, store)
	store.dispatch(appActions.toggle(false))
}

// const registerServiceWorker = () => true

export {
	wrapRootElement,
	onRouteUpdate,
	onPreRouteUpdate,
	onClientEntry,
	onInitialClientRender,
	// registerServiceWorker
    // registerServiceWorker
}
import React, { useReducer, useEffect, useLayoutEffect } from "react"
import AppCtx from "./ctx"
import initialState from "./state"
import actions from "./actions"

const AppProvider = ({ children, onLoad = () => false }) => {
  const [state, dispatch] = useReducer(actions, initialState)

  useLayoutEffect(() => {
    onLoad(dispatch)
  }, [])

  useEffect(() => {
    console.log("AppCtx state ", state)
    localStorage.setItem("AppCtx", JSON.stringify(state))
    // onStateChange(state)
  }, [state])

  return (
    <AppCtx.Provider
      value={{
        state: { ...state },
        dispatch,
      }}
    >
      {children}
    </AppCtx.Provider>
  )
}

export { AppProvider, AppCtx }

import React from "react"
import initialState from "./state"

const AppCtx = React.createContext({
  state: { ...initialState },
  actions: {
    loaded(state, { payload }) {},
  },
})

export default AppCtx

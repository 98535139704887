import React, {
  useReducer,
  useCallback,
  useEffect,
  useLayoutEffect,
  useContext,
  useRef,
} from "react"
import SearchCtx from "./ctx"
import initialState from "./state"
import actions from "./actions"

const SearchProvider = ({
  children,
  onLoad = () => false,
  onStateChange = () => false,
}) => {
  const [state, dispatch] = useReducer(actions, initialState)

  useLayoutEffect(() => {
    onLoad(dispatch)
  }, [])

  useEffect(() => {
    console.log("SearchCtx state ", state)
    onStateChange(state)
    localStorage.setItem("SearchCtx", JSON.stringify(state))
  }, [state])

  return (
    <SearchCtx.Provider
      value={{
        state: { ...state },
        dispatch,
      }}
    >
      {children}
    </SearchCtx.Provider>
  )
}

export { SearchProvider, SearchCtx }

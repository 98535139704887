const findInString = (str, val) => str.toLowerCase().includes(val.toLowerCase())

const actions = {
  setPhrase(state, { payload }) {
    let phrase = payload
    let filtered = []

    if (phrase.length > 2) {
      filtered = [...state.all].filter((el) => {
        return [
          findInString(el.title, phrase),
          findInString(el.slug, phrase),
          findInString(el.content, phrase),
        ].includes(true)
      })
    }

    return {
      ...state,
      filtered,
      phrase,
    }
  },
  loadDataFromApi(state, { payload }) {
    const { products, posts, pages } = payload

    return {
      ...state,
      all: [...products, ...posts, ...pages],
      imBusy: false,
    }
  },
  onRequestError(state, { payload }) {
    return {
      ...state,
      error: payload,
      imBusy: false,
    }
  },
}

function searchReducer(state, action) {
  switch (action.type) {
    case "setPhrase":
      return actions.setPhrase(state, action)
    case "loadDataFromApi":
      return actions.loadDataFromApi(state, action)
    case "onRequestError":
      return actions.onRequestError(state, action)
  }
}

export default searchReducer

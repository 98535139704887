import React from "react"
import initialState from "./state"

const CartCtx = React.createContext({
  state: { ...initialState },
  actions: {
    changeValue(state, { payload }) {},
    loadCustomer(state, { payload }) {},
  },
})

export default CartCtx

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-blog-js": () => import("./../../../src/templates/blog/blog.js" /* webpackChunkName: "component---src-templates-blog-blog-js" */),
  "component---src-templates-blog-categories-js": () => import("./../../../src/templates/blog/categories.js" /* webpackChunkName: "component---src-templates-blog-categories-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog/post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-blog-single-category-js": () => import("./../../../src/templates/blog/single-category.js" /* webpackChunkName: "component---src-templates-blog-single-category-js" */),
  "component---src-templates-ecommerce-categories-js": () => import("./../../../src/templates/ecommerce/categories.js" /* webpackChunkName: "component---src-templates-ecommerce-categories-js" */),
  "component---src-templates-ecommerce-checkout-js": () => import("./../../../src/templates/ecommerce/checkout.js" /* webpackChunkName: "component---src-templates-ecommerce-checkout-js" */),
  "component---src-templates-ecommerce-product-js": () => import("./../../../src/templates/ecommerce/product.js" /* webpackChunkName: "component---src-templates-ecommerce-product-js" */),
  "component---src-templates-ecommerce-shop-js": () => import("./../../../src/templates/ecommerce/shop.js" /* webpackChunkName: "component---src-templates-ecommerce-shop-js" */),
  "component---src-templates-ecommerce-single-category-js": () => import("./../../../src/templates/ecommerce/single-category.js" /* webpackChunkName: "component---src-templates-ecommerce-single-category-js" */),
  "component---src-templates-ecommerce-summary-js": () => import("./../../../src/templates/ecommerce/summary.js" /* webpackChunkName: "component---src-templates-ecommerce-summary-js" */),
  "component---src-templates-standard-about-js": () => import("./../../../src/templates/standard/about.js" /* webpackChunkName: "component---src-templates-standard-about-js" */),
  "component---src-templates-standard-answers-js": () => import("./../../../src/templates/standard/answers.js" /* webpackChunkName: "component---src-templates-standard-answers-js" */),
  "component---src-templates-standard-arrange-meeting-js": () => import("./../../../src/templates/standard/arrange-meeting.js" /* webpackChunkName: "component---src-templates-standard-arrange-meeting-js" */),
  "component---src-templates-standard-contact-js": () => import("./../../../src/templates/standard/contact.js" /* webpackChunkName: "component---src-templates-standard-contact-js" */),
  "component---src-templates-standard-faq-js": () => import("./../../../src/templates/standard/faq.js" /* webpackChunkName: "component---src-templates-standard-faq-js" */),
  "component---src-templates-standard-gladiators-js": () => import("./../../../src/templates/standard/gladiators.js" /* webpackChunkName: "component---src-templates-standard-gladiators-js" */),
  "component---src-templates-standard-home-js": () => import("./../../../src/templates/standard/home.js" /* webpackChunkName: "component---src-templates-standard-home-js" */),
  "component---src-templates-standard-other-js": () => import("./../../../src/templates/standard/other.js" /* webpackChunkName: "component---src-templates-standard-other-js" */),
  "component---src-templates-standard-regulations-js": () => import("./../../../src/templates/standard/regulations.js" /* webpackChunkName: "component---src-templates-standard-regulations-js" */),
  "component---src-templates-standard-support-js": () => import("./../../../src/templates/standard/support.js" /* webpackChunkName: "component---src-templates-standard-support-js" */),
  "component---src-templates-standard-tag-js": () => import("./../../../src/templates/standard/tag.js" /* webpackChunkName: "component---src-templates-standard-tag-js" */),
  "component---src-templates-standard-tags-js": () => import("./../../../src/templates/standard/tags.js" /* webpackChunkName: "component---src-templates-standard-tags-js" */),
  "component---src-templates-standard-tasks-js": () => import("./../../../src/templates/standard/tasks.js" /* webpackChunkName: "component---src-templates-standard-tasks-js" */),
  "component---src-templates-standard-tech-js": () => import("./../../../src/templates/standard/tech.js" /* webpackChunkName: "component---src-templates-standard-tech-js" */),
  "component---src-templates-standard-thanks-js": () => import("./../../../src/templates/standard/thanks.js" /* webpackChunkName: "component---src-templates-standard-thanks-js" */)
}


import React from "react"
import initialState from "./state"

const SearchCtx = React.createContext({
  state: { ...initialState },
  actions: {
    setPhrase(state, { payload }) {},
    setSearchData(state, { payload }) {},
    onSearchError(state, { payload }) {},
  },
})

export default SearchCtx

import { reducer as cartReducer, actions as cartActions } from "./cart"
import {
  reducer as customerReducer,
  actions as customerActions,
} from "./customer"
import { reducer as searchReducer, actions as searchActions } from "./search"
import { reducer as appReducer, actions as appActions } from "./app"
import { configureStore } from "@reduxjs/toolkit"
import { throttle } from "lodash"

const loadState = (defaultState) => {
  try {
    const serializedState = localStorage.getItem("state")
    if (serializedState === null) {
      return null
    }
    return JSON.parse(serializedState)
  } catch (err) {
    return null
  }
}

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state)
    localStorage.setItem("state", serializedState)
  } catch (err) {
    // ignore write errors
  }
}

const store = configureStore({
  reducer: {
    Cart: cartReducer,
    Customer: customerReducer,
    Search: searchReducer,
    App: appReducer,
  },
})

store.subscribe(
  throttle(() => {
    // console.log("onchange", store.getState())
    saveState(store.getState())
  }, 1000)
)

// tego nie powinno być - to dokonuje się po imporcie
const { loadCart, setShippings } = cartActions
const { loadCustomer } = customerActions
const { setSearchData, onSearchError } = searchActions

export default store

export {
  cartReducer,
  cartActions,
  customerReducer,
  customerActions,
  saveState,
  loadState,
  loadCart,
  loadCustomer,
  searchReducer,
  searchActions,
  setSearchData,
  onSearchError,
  setShippings,
  appActions,
}

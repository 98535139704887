import React from "react"
import initialState from "./state"

const CartCtx = React.createContext({
  state: { ...initialState },
  actions: {
    addToCart: () => {},
    removeFromCart: () => {},
    changeProductQty: () => {},
    setShippings: () => {},
    setShipping: () => {},
    setOrder: () => {},
    setCoupon: () => {},
    onError: () => {},
    loadCart: () => {},
    resetCart: () => {},
  },
})

export default CartCtx

import { createSlice } from "@reduxjs/toolkit"

const initial_state = {
  customer: {
    username:'',
    password:'',
    first_name: '',
    last_name: '',
    address_1: '',
    address_2: '',
    city:  '',
    postcode:  '',
    country:  '',
    email: '',
    phone:  '',
    note:''
  }
}

const { actions, reducer } = createSlice({
  name: "Customer",
  initialState: initial_state,
  reducers: {
    changeValue(state, action) {
      const [key, value] = Object.entries(action.payload)[0]
      state.customer[key] = value
    },
    loadCustomer(state, {payload}) {
      const {customer} = payload
      state.customer = customer
    },
  }
})

export { actions, reducer }
